<template>
  <footer class="container">
    <div class="flex flex-col-reverse justify-between gap-8 rounded-t-2xl bg-gray-50 p-8 max-md:flex-col md:flex-row md:p-16 md:pr-24">
      <!-- Left -->
      <div class="mt-8 flex flex-col justify-between md:mt-0">
        <div>
          <Logo class="h-9" />
          <div class="mt-3 flex gap-3 py-4">
            <NuxtLink href="https://www.facebook.com/picupaus" class="text-gray-400 hover:text-green-600">
              <Icon name="fa6-brands:facebook" class="h-6 w-6" />
            </NuxtLink>
            <NuxtLink href="https://www.instagram.com/picup.com.au/" class="text-gray-400 hover:text-green-600">
              <Icon name="fa6-brands:instagram" class="h-7 w-7" />
            </NuxtLink>
            <NuxtLink href="https://www.tiktok.com/@picup.com.au" class="text-gray-400 hover:text-green-600">
              <Icon name="fa6-brands:tiktok" class="h-6 w-6" />
            </NuxtLink>
          </div>
        </div>
        <p class="pt-4 text-gray-400">© Copyright {{ year }}. All rights reserved.</p>
      </div>

      <!-- Right -->
      <nav class="flex flex-col flex-wrap gap-8 md:flex-row md:gap-24">
        <div v-for="(section, key) in links">
          <h5 class="text-xl font-semibold">{{ humanise(key) }}</h5>
          <ul class="mt-4 flex flex-col gap-2">
            <li v-for="(link, id) in links[key]">
              <NuxtLink :to="link" class="font-light text-gray-600 hover:text-green-600">{{ humanise(id) }}</NuxtLink>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </footer>
</template>

<script setup lang="ts">
  const year = new Date().getFullYear()

  const links = {
    services: {
      delivery: '/#services',
      junk_removal: '/#services',
      removalist: '/#services'
    },
    company: {
      // about_us: '/about',
      drivers: '/apply-to-drive',
      contact: '/contact'
    },
    product: {
      online_bookings: '/bookings',
      account: '/account',
      login: '/login'
    },
    legal: {
      terms: '/legal/termsandconditions',
      privacy: '/legal/privacy'
    }
  }
</script>
